.internalTxnViewAllNavbar {
  width: 100%;
}

.internalTxnViewAllSec1 {
  width: 100%;
  background: #3a5686;
  position: fixed;
  z-index: 1001;
  opacity: 1;
  /* height: 208px; */
}

.infinite-scroll-component__outerdiv {
  width: 100%;
}

.acc_blackTxn_div {
  position: absolute;
  opacity: 0.8;

  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;
}

.internalTxnViewAllSec2 {
  /* width: 1024px; */
  /* display: flex; */
  /* background: linear-gradient(to right, #506994 32.5%, #3a5686 20%); */
  height: 59px;
  /* justify-content: flex-end; */
  /* align-items: end; */
  margin: auto;
  position: relative;
  top: 100px;
  /* bottom: -94px; */
}

.internalTxnViewAll_middleBar {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  /* height: 59px; */
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
}

.internalTxnViewAllWholeSection {
  width: 463px;
  display: flex;
  background-color: #506994;
  height: 92px;
  position: relative;
}

/* @media screen and (max-width: 1024px) {
  .internalTxnViewAllWholeSection {
    width: 430px;
  }
} */

.internalTxnViewallAmountSection {
  display: flex;
  align-items: center;
  flex-direction: column;
  /* margin-right: 90px; */
  height: 49px;
  /* border-left: 1px solid #1d3a6d; */
  margin-top: 20px;
}

.internalTxnViewAllParentdiv {
  display: flex;
  flex-direction: column;
  gap: 04px;
}

.internalTxnTotalCount {
  display: inline-block;
  transform: rotate(270deg);
  font-style: italic;
  color: #ffffff;
  margin-right: 5px;
  font-size: 15px;
}

.internaltxnViewallCount {
  font: normal normal 600 20px/27px Open Sans;
}

.internalTxnViewAllchild1 {
  /* width: 120px;
  height: 27px; */
  text-align: left;
  letter-spacing: 0px;
  color: #f9f9f9;
  opacity: 1;
  white-space: nowrap;
  /* margin-left: 10px; */
}

.ver__divider {
  border-left: 1px solid var(--Blue_1);
  height: 60%;
  position: absolute;
  /* top: 5px; */
  /* left: 10px; */
  top: 62%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.internalTxnViewallTotalAmount {
  width: 180px;
  height: 27px;
  text-align: left;
  letter-spacing: 0px;
  color: #f9f9f9;
  opacity: 1;
  white-space: nowrap;
}

.internalTxnViewAllchild2 {
  width: 100px;
  height: 17px;
  text-align: center;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #dadada;
  opacity: 1;
  margin-left: -3px;
  margin-top: 8px;
}

.internalTxn_filterdropdown {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  width: 100%;
  max-width: 1024px !important;
  z-index: 999999;
}

.internalTxn_viewall_calendar_icon {
  margin-top: -2px;
  height: 15px;
  align-items: center;
  justify-content: center;
  width: 20px !important;
}

.internalTxn_filterdropdown-container-div {
  background-color: #3a5686;
  margin-top: 150px;
  /* height: 90px; */
  border-radius: 0 !important;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

.MultiApprovalclear {
  width: 87px;
  height: 25px;
  box-shadow: 0px 3px 6px #00000029;
  border: 0.5px solid #f9f9f9;
  border-radius: 8px;
  padding: 3px 27px;
  opacity: 1;
}

.transactionAmountTitle {
  width: 114px;
  height: 17px;
  text-align: center;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #dadada;
  opacity: 1;
  margin-left: 20px;
  position: relative;
  right: 30px;
  margin-top: 4px;
}

/* .internalTxn_viewall_filter_div {
  margin-left: auto;
} */

.internalTxn_viewall_filter_div.filtered .internalTxnViewAll_filter_container {
  background: #f9f9f9;
  /* margin-left: auto; */
}

.internalTxnViewAll_filter_container {
  display: flex;
  justify-content: space-between;
  width: 50px;
  height: 30px;
  padding: 9px;
  margin-top: 20px;
  background: #244277;
  /* box-shadow: 0px 3px 6px #00000029; */
  border-radius: 5px;
  opacity: 1;
  cursor: pointer;
  margin-left: 20px;
}



.internal-txn-filter-image {
  width: 12px !important;
  height: 12px;
}

.internalTxn_viewAll_search_div {
  margin-left: 30px;
  width: 50px;
  background-color: #244277;
  margin-top: 20px;
  border-radius: 5px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  /* gap: 6px; */
  height: 30px;
  cursor: pointer;
}

@media screen and (max-width: 1024px) {
  .internalTxn_viewAll_search_div {
    margin-left: 15px !important;
  }

  .internalTxnViewAll_middleBar {
    justify-content: start !important;
  }

  .internalTxnViewAllWholeSection {
    width: 26rem;
  }

  .internalTxnViewAll_middleBar .totalBalance {
    margin-left: 15px;
  }

  .bene-txn-distri-viewall-popover {
    margin-left: 0 !important;
  }
}

.internalTxn_search-image {
  width: 14px;
  height: 14px;
}

/* @media screen and (max-width: 2400px) and (min-width: 1500px) {
  .internalTxn_viewAll_search_div {
    margin-right: 1rem;
  }
}

@media screen and (max-width: 3840px) and (min-width: 2560px) {
  .internalTxn_viewAll_search_div {
    margin-right: 1rem;
  }
} */

.internalTxnViewallDistriSection {
  display: flex;
  align-items: center;
  flex-direction: column;
  /* margin-right: 90px; */
  height: 49px;
  /* border-left: 1px solid #1d3a6d; */
  margin-top: 20px;
}

.internalTxnViewallDistri {
  width: 180px;
  height: 27px;
  text-align: left;
  letter-spacing: 0px;
  color: #f9f9f9;
  opacity: 1;
  white-space: nowrap;
}

.internal-txn-no-acc-image {
  /* width: 288px; */
  height: 250px;
  opacity: 0.9;
}

.internal-txn-no-internalaccount-text,
.no-internalaccount-text2 {
  color: #f9f9f9;
  opacity: 1;
  font-size: 1rem;
  text-align: center;
  /* margin-top: 12px; */
}

.no-internalaccount-text2 {
  display: inline-flex;
  padding-bottom: 18px;
}

/* .internal-txn-viewall-loader-container {
  img {
    width: 49px;
    height: 50px;
  }
} */

.internalTxnViewAll_middle_section_container {
  /* margin-top: 232px; */
  display: flex !important;
  justify-content: space-between !important;
  width: 89%;
  margin: 0 auto;
  position: relative;
  margin-bottom: 13px;
}

.internalTxn_viewall_filter_div_left {
  display: flex;
  align-items: center;
  margin: 10px 0;
  margin-left: 12px;
  text-align: left;
  font: normal normal normal 14px/22px Open Sans;
  letter-spacing: 0px;
  color: #f9f9f9;
  opacity: 1;
}

/* .internalTxn_viewall_filter_div_left p {
  top: 149px;
  left: 330px;
  height: 18px;
} */

.internalTxn_viewall_filter_div_left .internalTxn_viewall_filter_body {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 40px;
  /* box-shadow: 0px 3px 6px #00000024; */
  border: 2px solid #667ca3;
  border-radius: 48px;
  opacity: 1;
}

.internalTxn_viewall_filter_div_left .internalTxn_viewall_filter_img_div {
  height: 20px;
  width: 20px;
  background-color: #ffffff;
  border-radius: 26px;
  opacity: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.internalTxn_viewall_filter_div_left .internalTxn_viewall_filter_img_div>img {
  width: 10px;
  height: 10px;
}

.internalTxn_viewall_filter_div_left .internalTxn_viewall_close_btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-color: #667ca3; */
  /* border-radius: 26px; */
  opacity: 1;
}

.internalTxn_viewall_filter_div_left .internalTxn_viewall_close_btn>img {
  height: 20px;
  width: 20px;
}

#txnViewallScrollContainer {
  display: flex !important;
  flex-direction: column !important;
  overflow: auto;
  position: relative;
  height: 100%;
}

.internalTxnViewallCard {
  left: 5rem;
  padding-top: 0px;
}

@media screen and (min-width: 1920px) and (min-height: 1080px) {
  .internalTxnViewallCard {
    left: 6rem;
  }
}

@media screen and (min-width: 3840px) and (min-height: 1920px) {
  .internalTxnViewallCard {
    left: 12rem;
  }
}

.internal-txn-viewall-page-select-div {
  margin-top: 20px;
  margin-left: 20px;
  height: 34px !important;
  /* width: 130px !important; */
  cursor: pointer;
  display: flex;
  background: #244277;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
  opacity: 1;
}

.internal-txn-viewall-pagination-containers {
  margin-top: 20px;
  margin-left: 20px;
  height: 34px !important;
  width: 132px !important;
  /* cursor: pointer; */
  display: flex;
  background: #244277;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
  opacity: 1;
}

.internal_viewall_data_dropdown {
  border-radius: 0px 5px 5px 0px;
  padding: 8px 0px 0px 0px;
  display: flex;
  gap: 5px;
  cursor: pointer;
}

.internalTxn_dropdown_options {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #ffff;
  width: 60px;
  height: 33px;
  font-size: 12px;
  padding-left: 12px;
}

.internalTxn_dropdown_options:hover {
  background-color: rgb(86, 110, 151);
}

.internalTxn_viewall_toggle_containers {
  margin-top: 20px;
  margin-left: 20px;
  height: 30px !important;
  width: 68px;
  display: flex;
  justify-content: space-around;
  cursor: pointer;
  background-color: #244277;
  /* box-shadow: 0px 3px 6px #00000029; */
  border-radius: 5px;
  opacity: 1;
}

.internalTxn_viewAll_search_div.searched {
  background: #f9f9f9;
}

.internalTxn_viewall_pagination_input {
  font-family: "Open Sans", sans-serif;
  /* font-weight: 600; */
  font-size: 12px;
  line-height: 20px;
  padding: 4px 7px;
  width: 38px;
  height: 33px;
  text-align: center;
  border: 1px solid #fff;
  /* border-radius: 4px; */
  background-color: #f9f9f9;
  color: #314d7e;
  box-shadow: none;
}

.internalTxn_viewall_pagination_input:focus {
  outline: none;
  border-color: #fff;
}

.txn-main-btn {
  margin: 0 !important;
  width: 57px !important;
  font: normal normal normal 12px / 17px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: none !important;
  position: absolute !important;
  z-index: 1 !important;
  background-color: #667ca3 !important;
  display: flex !important;
  justify-content: space-evenly;
  overflow: hidden !important;
  transition: all 0.2s ease !important;
}

.txn_high_to_low_select_select {
  display: flex;
  justify-content: space-between;
  /* width: 100%;
    height: 39px; */
  padding-right: 10px;
  align-items: center;
  flex-wrap: wrap;
  position: absolute;
  top: 0;
}

.txn_high_to_low_select_scroll {
  position: absolute;
}

.txn_select_scroll_com {
  height: 24px !important;
  border-radius: 5px !important;
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
  text-align: left !important;
  font: normal normal normal 12px / 15px Open Sans !important;
  letter-spacing: 0px !important;
  margin: 4px 0px !important;
  color: #ffffff !important;
  text-transform: none !important;
  padding: 2px 4px;
}

@media screen and (max-width: 900px) and (min-width: 800px) {

  .ver__divider {
    left: 51% !important;
  }

  .internalTxnViewAllWholeSection {
    justify-content: center;
  }

  .internalTxnViewAll_middleBar .totalBalance {
    margin-left: 10px;
  }
    .bene-txn-distri-viewall-popover{
      width:205px!important
    }
}

@media screen and (max-width: 800px) {
  .beneTxnViewallTotalAmount {
    width: 100% !important;
  }
}