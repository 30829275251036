.bene_txn_search-image {
  width: 14px;
  height: 14px;

}

#txn_search_input:focus {
  
  padding-left: 50px;
  padding-right: 35px;
}
#txn_search_input {
  /* margin-top: -13px; */
  width: 1024px;
  padding-left: 50px;
  padding-right: 35px;
  height: 36px;
  border-radius: 10px;
}
.bene_txn_viewall_search_inner_image {
  width: 20px;
  height: 20px;
}
.bene_txn_search_close_image {
  width: 16px;
  height: 16px;
}
.bene-txn-searchbar {
  margin-top: 201px;
  height: 0px;
  border-radius: 0 !important;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}
.bene-txn-searchbar.open {
  height: 90px;
  animation: growDown 300ms ease-in-out forwards;
  transform-origin: top center;
}

@keyframes growDown {
  0% {
    transform: scaleY(0);
  }

  100% {
    transform: scaleY(1);
  }
}
.bene-txn-searchbar.closed {
  transition: all 0.2s ease-in;
  height: 0;
}
.bene-txn-viewall-search-bar {
  display: flex;
  width: 100%;
  max-width: 1024px !important;
  /* margin: 0 auto; */
  z-index: 1;
  flex-direction: row;
  /* border-bottom: 1px solid #1D3A6D; */
}

.bene_txn_viewall_search_button {
  width: 105px;
  height: 45px;
  background: #707070 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  margin-left: 19px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bene_viewall_search_inner_image_acc {
  width: 15px;
  height: 15px;
  position: absolute;
  top: 0px;
  left: 20px;
}
