.all_bene_txn_popover_btn::first-letter {
  text-transform: capitalize !important;
}

#bene_txn_distri_popover_section {
  display: flex !important;
  flex-wrap: wrap !important;
  justify-content: flex-start !important;
  gap: 15px;
  padding: 20px 0px 1px 0px !important;
  background-color: #506994;
  color: #f9f9f9;
  font: normal normal normal 600 14px / 19px Open Sans;
}

.bene_txn_distri_popover_section a {
  height: 26px !important;
  text-align: center !important;
  display: inline-flex;
  align-items: center !important;
  justify-content: center !important;
  text-decoration: none !important;
}

.bene_txn_selected_btn_approved .bene_txn_selected_btn_denied .bene_txn_selected_btn.pending .bene_txn_selected_btn.blocked .bene_txn_selected_btn.abandoned ::first-letter {
  text-transform: capitalize !important;
}

/* .bene_txndistri_status_icon {
  width: 12px !important;
  height: 12px !important;
} */

.view-all_bene_txn_popover-main {
  position: relative;
  display: inline-block;
}

.all-txn-status-icon,
.all-txn-status-icon special-checkmark {
  margin-left: 5px;
  width: 18px;
  height: 18px;
}

.pop_overbtn.pending,
.pop_overbtn.abandoned,
.pop_overbtn.all ::first-letter {
  text-transform: capitalize !important;
}

.bene-txn-distri-viewall-popover {
  height: 0;
  background-color: #506994;
  width: 232px;
  margin-top: 0;
  margin-left: 1px;
  /* padding: 10px 0; */
}

.bene-txn-distri-viewall-popover.open {
  visibility: visible;
  height: 333px;
  transition: height 0.2s ease-in;
  padding: 10px;
}

.bene-txn-distri-viewall-popover.closed {
  visibility: hidden;
  height: 0;
  margin-top: -22px;
  transition: height 0.2s ease-in, visibility 0s linear 02s;
  margin-top: -5px;
}

.bene_distri_viewall_popover_icon {
  position: relative;
  bottom: 23px;
  left: 176px;
  width: 20px;
  height: 20px;
  background: #1d3a6d;
  border-radius: 3px;
}

.bene_viewall_distri_subHeading {
  width: 100px;
  height: 17px;
  text-align: center;
  font: normal normal normal 12px / 17px Open Sans;
  letter-spacing: 0px;
  color: #dadada;
  opacity: 1;
  margin-left: -1px;
  margin-top: 7px;
}

.beneTxnViewAllDistriPopover {
  width: 230px;
  height: 82px;
  cursor: pointer;
}

.benTxnViewall_distri_inner_div {
  width: inherit;
  margin-top: 12px;
}

@media screen and (max-width: 800px) {
  .bene-txn-distri-viewall-popover {
    width: 100% !important;
  }

  .benTxnViewall_distri_inner_div {
    width: 100% !important;
    gap: 0 !important;
    justify-content: space-between;
    padding: 0 18px!important;
  }
}

@media screen and (max-width: 500px) {
  .bene-txn-distri-viewall-popover.open {
    padding: 7px;
  }

    .benTxnViewall_distri_inner_div {
      padding: 0 3px 0 15px !important
    }
    .beneTxnViewAllDistriPopover {
      -webkit-tap-highlight-color: transparent;
    }
  
    .beneTxnViewAllPopover {
      -webkit-tap-highlight-color: transparent;
    }
}