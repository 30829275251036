.settle_main_div_BankAccount {
  margin: 10px 30px;
  border-bottom: 1px solid #172e57;
  padding-bottom: 10px;
  align-items: flex-start;
  margin-bottom: 5px;
}

.settle_main_div_BankAccount p {
  width: 164px;
  height: 16px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.34px;
  color: #dadada;
}

.settle_main_div_BankAccount h6 {
  width: 164px;
  height: 22px;
  font-size: 16px;
  font-weight: 400;
  line-height: 21.79px;
  color: #f9f9f9;
}

.settle_main_div_container_internal {
  width: 100%;
  border-radius: 30px;
  position: relative;
  /* margin: 10px 0; */
}

.beneSettle_Main1 {
  scroll-snap-align: start;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: fit-content;
  height: fit-content;
  width: 472px;
}

.Inter_settle_main_div_container_img {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 110px;
  height: 30px;
  background: #f9f9f9 0% 0% no-repeat padding-box;
  border-radius: 20px 0px;
  opacity: 1;
  object-fit: contain;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 26px;
}

.Inter_settle_main_div_container_img > img {
  width: 65px;
  height: auto;
}

.Inter_settleType_main_div {
  width: 472px;
  /* height: 287px; */
  background: #314d7e 0% 0% no-repeat padding-box;
  border-radius: 20px;
  opacity: 1;
  /* margin-left: 40px; */
  margin: 20px 0px;
  padding: 20px 0px 20px 0px;
}

.internal_new_balance_New {
  width: 180px;
  height: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.34px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #dadada;
}

.Inter_settleType_main_div > p {
  width: auto;
  height: 22px;
  text-align: left;
  font: normal normal 600 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #dadada;
  opacity: 1;
  margin-left: 20px;
}

.Inter_settleType_neft_imps_rfgs_container {
  width: 470px;
  height: 92px;
  background: #506994 0% 0% no-repeat padding-box;
  border-radius: 20px;
  opacity: 1;
  margin-top: 10px;
  position: relative;
  display: flex;
}

.Inter_settleType_neft_imps_rfgs_container_inner {
  width: 236px;
  height: 92px !important;
  border-radius: 20px !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  text-transform: none !important;
}

.Inter_settleType_neft_imps_rfgs_container_back {
  width: 235px;
  height: 92px;
  background: #69b76f 0% 0% no-repeat padding-box;
  opacity: 1;
  position: absolute;
  transition: all 0.4s ease;
  top: 0;
  left: 0;
  cursor: pointer;
}

.Inter_settleType_neft_imps_rfgs_container_inner > h6 {
  width: 40px;
  height: 22px;
  text-align: center;
  font: normal normal 600 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #f9f9f9;
  opacity: 1;
  z-index: 3;
}

.Inter_settleType_neft_imps_rfgs_container_inner > p {
  width: 142px;
  height: 34px;
  text-align: center;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  opacity: 1;
  z-index: 3;
}

.Inter_settle_main_div_container_img {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 110px;
  height: 30px;
  background: #f9f9f9 0% 0% no-repeat padding-box;
  border-radius: 20px 0px;
  opacity: 1;
  object-fit: contain;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Inter_settle_main_div_container_img > img {
  width: 65px;
  height: auto;
}

.Inter_settleType_neft_imps_rfgs_container {
  width: 470px;
  height: 92px;
  background: #506994 0% 0% no-repeat padding-box;
  border-radius: 20px;
  opacity: 1;
  margin-top: 20px;
  position: relative;
  display: flex;
}

.Inter_settleType_neft_imps_rfgs_container_inner {
  width: 236px;
  height: 92px !important;
  border-radius: 20px !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  text-transform: none !important;
}

/* .Inter_settleType_neft_imps_rfgs_container_back {
  width: 233px;
  height: 92px;
  background: #69b76f 0% 0% no-repeat padding-box;
  border-radius: 20px;
  opacity: 1;
  position: absolute;
  transition: all 0.4s ease;
  top: 0;
  left: 0;
  cursor: pointer;
} */

.Inter_settleType_neft_imps_rfgs_container_inner > h6 {
  width: 40px;
  height: 22px;
  text-align: center;
  font: normal normal 600 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #f9f9f9;
  opacity: 1;
  z-index: 3;
}

.Inter_settleType_neft_imps_rfgs_container_inner > p {
  width: 142px;
  height: 34px;
  text-align: center;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  opacity: 1;
  z-index: 3;
}

.right_side_select_owner_div_top_img_res {
  position: absolute;
  right: 0;
  width: 46px;
  top: 0;
  height: 35px;
  background-color: #667ca3;
  padding: 5px 12px;
}

.right_side_select_owner_div_top_res {
  display: flex;
  flex-direction: column;
  margin: 10px 0px 20px 0px;
  width: 100%;
  position: relative;
  background: #3a5686;
  padding: 20px;
  border-radius: 10px;
  align-items: center;
}

.select_owner_box_res {
  width: 100%;
}

.Inter_select_owner_butt_res {
  width: 90%;
  height: 43px;
  background: #244277 0% 0% no-repeat padding-box !important;
  border-radius: 5px;
  margin: 9px 19px 15px 20px !important;
  text-transform: none;
}

.Refresh {
  color: #dadada;
  padding-left: 4px;
  font-size: 14px;
}
.Toastify__toast-container {
  right: 2em !important;
}
