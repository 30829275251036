.internalTxnDetailsPage {
  display: block;
  width: 100%;
  background-color: var(--Blue_6);
  height: auto;
}

.internaltxn-account-primary-details {
  height: 30%;
  background-color: var(--Blue_6);
  width: 100%;
  display: flex;
  position: relative;
}

.url-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.progress-require-approval::-webkit-progress-bar {
  background-color: var(--Blue_2);
  border-radius: 15px;
}

.progress-require-approval::-webkit-progress-value {
  background-color: var(--Button_green);
  border-radius: 15px;
}

.progress-rejected-approval::-webkit-progress-bar {
  background-color: var(--Blue_2);
  border-radius: 15px;
}

.progress-rejected-approval::-webkit-progress-value {
  background-color: var(--Failed);
  border-radius: 15px;
}

.progress-abandoned-transaction::-webkit-progress-bar {
  background-color: var(--Blue_2);
  border-radius: 15px;
}

.progress-abandoned-transaction::-webkit-progress-value {
  background-color: var(--Abandoned);
  border-radius: 15px;
}

.internalTxnDetailsPageMob {
  display: none;
}

.infoBox {
  width: 20rem;
}

@media screen and (max-width: 1024px) {
  .internalTxnDetailsPage {
    display: none;
  }

  .internalTxnDetailsPageMob {
    display: block;
    height: 100vh;
    overflow: hidden scroll;
    scrollbar-width: none;
  }
}
.approval_timeline_line {
  width: 4%;
}
@media screen and (max-width: 340px) {
  .approval_timeline_line {
    width: 7% !important;
  }
}

@media screen and (max-width: 360px) {
  .infoBox {
    width: 17rem;
  }
}

@media screen and (max-width: 380px) and (min-width: 361px) {
  .infoBox {
    width: 17rem;
  }
}

@media screen and (max-width: 400px) and (min-width: 381px) {
  .infoBox {
    width: 18rem;
  }
}

@media screen and (max-width: 420px) and (min-width: 401px) {
  .infoBox {
    width: 19rem;
  }
}

.display_480 {
  display: none;
}

@media screen and (max-width: 480px){
  .display_below_480{
    display: block;
  }

  .infoBox{
    margin-left: 56px;
    margin-top: -16px;
  }
}
@media screen and (min-width: 481px) {
  .display_480{
    display: block;
  }

  .display_below_480 {
    display: none;
  }
}
@media screen and (max-width: 1024px) {
  .approval_timeline_line {
    width: 20px !important;
  }
}
.Distribution-box {
  border-top: 1px solid #031f4f !important;
}
@media screen and (min-width: 1024px) {
  .Distribution-box {
    border-top: none !important;
  }
}
@media screen and (min-width: 800px) and (max-width: 1024px) {
  .internal_txn_detail_container {
    padding-top: 3.5rem !important;
  }
}

@media (min-width: 1024px) and (max-width: 1040px) {
  .internaltxn-account-primary-details {
    padding: 0 10px;
  }
  .details-div-trans {
    padding: 0 10px;
  }
  .Txntimeline {
    padding: 0 10px;
  }
}
