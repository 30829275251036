.header {
  display: flex;
  width: 425px;
  height: 30px;
  background: #506994 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  opacity: 1;
  margin-bottom: 15px;
}

.internalTxn_list_section {
  margin: 5px 0px;
  display: flex;
  width: 425px;
  height: 49px;
  background: #314d7e 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;

  border-radius: 10px;
  opacity: 1;
}

.internalTxn_list_section:hover {
  /* position: absolute; */
  border: 1px solid #ff5860;
  background: #031f4f 0% 0% no-repeat padding-box;
  height: 98px !important;
  transition: height 0.2s;
  z-index: 998;
}

.internalTxn_list_section:hover .internalTxn_viewallList_hover_content {
  display: block !important;
  transition: all 0.2s ease 0.2s;
  position: absolute;
  top: 53%;
  left: 56px;
}

.internalTxn_list_distribution_div {
  position: absolute;
  top: 0;
  text-align: right;
  left: 116%;
  height: 44px;
  padding: 8px 0px;
  width: 143px;
  background-color: #031f4f;
}

.internalTxn_viewallList_hover_content {
  display: none;
}

.internalTxn_list_status_div {
  height: 44px;
  padding: 8px 0px;
  width: 187px;
  background-color: #031f4f;
  border-right: 2px solid #1d3a6d;
  border-left: 2px solid #1d3a6d;
}

.internalTxn-list-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 30px;
}

@media screen and (max-width: 2400px) and (min-width: 1800px) {
  .internalTxn-list-container {
    grid-template-columns: repeat(3, 1fr);
  }

  .internalTxn_list_section {
    width: 500px;
  }

  .internalTxn_list_accounts_div {
    width: 300px !important;
  }

  .internalTxn_list_status_div {
    width: 258px !important;
  }

  .internalTxn_list_amount_div {
    width: 150px !important;
  }
}

@media (min-width: 2560px) {
  .internalTxn-list-container {
    grid-template-columns: repeat(4, 1fr);
  }

  .internalTxn_list_section {
    width: 600px;
  }

  .internalTxn_list_accounts_div {
    width: 350px !important;
  }

  .internalTxn_list_status_div {
    width: 322px !important;
  }

  .internalTxn_list_amount_div {
    width: 180px !important;
  }
}

@media screen and (min-width: 2560px) and (max-width: 2700px) {
  .internalTxn-list-container {
    grid-template-columns: repeat(3, 1fr) !important;
  }
}

@media screen and (max-width: 3840px) and (min-width: 2700px) {
  .internalTxn-list-container {
    grid-template-columns: repeat(5, 1fr);
  }

  .internalTxn_list_section {
    width: 720px;
  }

  .internalTxn_list_accounts_div {
    width: 450px !important;
  }

  .internalTxn_list_status_div {
    width: 450px !important;
  }

  .internalTxn_list_amount_div {
    width: 150px !important;
  }
}

.bank-div {
  padding: 7px 20px;
  width: 70px;
  height: 49px;
}

.internalTxn_listview_bankRight_div_img {
  height: 49px;
  border-radius: 0px 10px 10px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  width: 56px;
  flex-shrink: 0;
}

.internalTxn_listview_bankRight_div_img > img {
  height: 40px;
  width: 60px;
  padding: 0px 3px;
}

.internalTxn_listview_bank_div_img {
  height: 49px;
  border-radius: 10px 0px 0px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  width: 56px;
  flex-shrink: 0;
}

.internalTxn_listviewall_bank_acc_div {
  justify-content: space-evenly;
  display: flex;
  color: #fafafa;
  font: normal normal 400 12px/16.34px Open Sans;

  /* padding: 0px 5px 8px 5px; */
}

.internalTxn_listviewall_arrow_icon {
  height: 17px;
  width: 18px;
  margin-top: 10px;
}

.internalTxn_listViewall {
  display: flex;
  flex-direction: column;
  width: 90px;
  height: 20px;
}

.internalTxn_list_section:hover .internalTxn_listview_bank_div_img {
  border-radius: 10px 0px 0px 0px;
}

.internalTxn_list_section:hover .internalTxn_listview_bankRight_div_img {
  border-radius: 0px 0px 0px 0px;
}

.internalTxn_listview_bank_div_img > img {
  height: 40px;
  width: 60px;
  padding: 0px 3px;
}

.internalTxn_list_name {
  text-transform: lowercase;
}

.internalTxn_list_name::first-line {
  text-transform: capitalize;
}

.bank-div > p {
  width: 30px;
  height: 17px;
  text-align: center;
  font: normal normal 600 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #f9f9f9;
  opacity: 1;
}

.internalTxn_list_accounts_div {
  height: 49px;
  padding: 8px 4px;
  /* width: 299px; */
  background-color: #506994;
  border-right: 2px solid #1d3a6d;
  border-left: 2px solid #1d3a6d;
}

.internalTxn_list_amount_div {
  height: 49px;
  padding: 5px 0px 5px 10px;
  min-width: 126px;
  background-color: #314d7e;
  border-radius: 0 10px 10px 0;

  /* border-left: 2px solid #1d3a6d; */
}

.internalTxn_list_section:hover .internalTxn_list_amount_div {
  border-radius: 0 10px 0 0 !important;
}

.internalTxn_list_section:hover .onhoverTxn {
  display: block !important;
  height: 44px;
  position: absolute;
  left: 0;
  width: 422px;
}

.onhoverTxn {
  display: none !important;
}

.internalTxn_list_last_div {
  width: 50px;
  height: 49px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 0px 10px 10px 0px;
  opacity: 1;
}

@media screen and (min-width: 800px) and (max-width: 1350px) {
  .internalTxn-list-container {
    grid-template-columns: repeat(2, 1fr) !important;
  }
}

@media screen and (max-width: 500px) {
  .internalTxn_list_section {
    width: 350px;
  }

  .internalTxn_list_status_div {
    width: 150px;
  }

  .internalTxn_list_distribution_div {
    width: 140px;
  }
}

/* .marquee {
  width: 200px;
  max-width: 200px;
  color: white;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
}
.marquee p {
  display: inline-block;
  padding-left: 100%;
  animation: marquee 7s linear infinite;
} */
/* @keyframes marquee {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
} */
